import React, { Component } from 'react'
import { graphql } from "gatsby"
import styled from 'styled-components'

import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/header"


const StyledArticle = styled.article`
  max-width: 1800px;
  margin-top: 6rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;

  @media( min-width: 768px ) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  h2 {
    margin-top: 6rem;
  }

  .ArticleDate {
    position: absolute;
    transform: rotate(-90deg);
    left: -50px;
    top: 50px;
  }

  .MainContent {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
  }

`;

export default class Page extends Component {
  render() {
    if (!this.props.data.wordpressPage) {
      return null;
    }
    const {
      data: {
        wordpressPage: {
          title,
          content,
          date,
          datetime,
        }
      }
    } = this.props

    return (
      <Layout>
        <SEO
          title={title}
          description={content.replace(/<[^>]*>?/gm, '').substr(0, 150)}
        />

        <Header />
        <StyledArticle>
          <h2>{title}</h2>
          <div className="ArticleDate" dateTime={datetime}>{date}</div>
          <div className="MainContent">
            <div dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </div>
        </StyledArticle>
      </Layout>
    )
  }
}

export const query = graphql`
  query PageQuery($slug: String!) {
    wordpressPage(slug: { eq: $slug }) {
      title
      excerpt
      date(formatString: "MMMM DD, YYYY")
      datetime: date(formatString: "YYYY-MM-DD hh:mm:ss")
      slug
      content
    }
  }
`


